class Api {

  _apiBase =  'http://image-pim.99hero.de';

  async getResource(url) {

    const res = await fetch(this._apiBase + url);
    if (!res.ok) {
      throw new Error(`Could not fetch ${url} recived ${res.status}`);
    }
    
    
    return res.json();
  }

  async getSeries() {

    return await this.getResource('/api/getSeries.php');
  }

  async getActiveStatus() {

    return await this.getResource('/api/status.php');
  }

  async send(formData) {
    const request = new Request(this._apiBase + `/api/uploadFile.php`, {
      method: 'POST',
      body: formData
    });
    return await 
    fetch(
    request
    );

  }
}

export default Api;
import React, { Component } from 'react';
import Api from '../../services/api';

const Status = class Status extends Component {

  _api = new Api();

  state = {
    processes: []
  }

  updateProcesses = async () => {

    let processes = await this._api
      .getActiveStatus();
    if (!processes) {
      return;
    }
    this.setState(
      { processes }
    );

  }

  componentDidMount() {

    this.updateProcesses();
    setInterval(this.updateProcesses, 3000);
  }
  render() {

    const { processes } = this.state;

    let items = 'not found';

   
    if (processes.length) {

      items = processes.map(({ id, series, logs }) =>  {

        return <li key={id} className="list-group-item">
                 <span> name: {series.replace('.txt', 1)} </span> 
                  <pre>
                    {logs}
                  </pre>
                  
               </li>
        }
      );

    }

    return (
      <div className="jumbotron rounded mt-3">
        <h1>Active</h1>

        <ul className="list-group">
          {items}
        </ul>
      </div>);
  }
}
export default Status
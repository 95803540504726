import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import Form from './form/form'
import Status from './status/status'



class App extends Component {

  
  render(){
   
    return (
      <div className="container">
        <div className="bd-example">
          <Form/>
        </div>
        <Status />
      </div>
    );
  }
}

export default App;

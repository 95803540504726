import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import Api from '../../services/api';
export default class Form extends Component {
  state = {
    options: [],
    files: null,
    series: null,
    selectedFile: null
  }

  _api = new Api();

  handleSubmit = async (e) => {

    e.preventDefault();

    const formData = new FormData();
    formData.append('file', this.state.files[0]); //event.target.files[0] data going here
    formData.append('series', this.state.series);

    await this._api
      .send(formData)
      .then( response => {
       /* if(response.status != 200) {

          response.json().then( m => alert(m));
          return
        }*/
         
      })
      .catch(error =>  console.log(e));

    return false;
  }
  constructor() {
    super();
    this.updateSeriesFolders();
  }


  async updateSeriesFolders() {

    let series = await this._api
      .getSeries();

    this.setState({
      options: series
    });
  }

  addFile = (e) => {
    const files = e.target.files;

    this.setState({
      files
    });

  }

  handleChange = (selectedOptions) => {

    this.setState({
      series: selectedOptions ? selectedOptions[0].name : ''
    });
  }



  render() {
    const { options, test } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group mt-3">
          <label htmlFor="exampleFormControlInput1">Series</label>
          <Typeahead
            // filterBy={filterBy === 'callback' ? filterByCallback : filterByFields}
            labelKey="name"
            options={options}
            required
            onChange={this.handleChange}

            id="my-typeahead-id"
            placeholder="Filter by state name or capital..."
            renderMenuItemChildren={(option) => (
              <div>
                {option.name}
              </div>
            )}
          />
        </div>

        <div className="custom-file mt-3">
          <input onChange={this.addFile} type="file" required className="custom-file-input" id="customFile" />
          <label className="custom-file-label" accept=".csv" htmlFor="customFile">Choose file</label>
        </div>

        <div className="custom-file mt-3">
          <button type="submit" className="btn btn-primary mb-2">Start render</button>
        </div>
      </form>
    );
  }
}